
@media (min-width: 1920px) {
    .list:nth-child(4n) {
        margin-right: 0;
    }
    .center_top_bar .list-unstyled > li {
        flex: initial;
        padding: 0 40px 10px;
    }
    /* .setting-categories .blue_tag_btn:nth-child(6n+0) {
        margin-right: 0;
    } */
    .speakers_card:nth-child(5n){
        margin-right: 0
    }   
    
    }
    
    @media (max-width: 1365px) {
    .list {
        margin: 10px 10px !important;
    }
    }
    
    @media screen and (min-width:1200px) and (max-width:1919px) {
    .create {
        display: none
    }
    .list {
        width: 332px;
        margin-right: 17px;
    }
    .center_top_bar .list-unstyled > li {
        font-size: 1.125rem;
    }
    .center_top_bar a .add {
        font-size: 1.5rem !important;
    }
    .tag_btn {
        margin-right: 10px;
    }
    }
    
    @media screen and (min-width:1200px) and (max-width:1245px) {
    .center_top_bar .list-unstyled > li {
        font-size: 16px;
    }
    }
    
    @media screen and (min-width:1200px) and (max-width:1335px) {
    .newfeed_section {
        text-align: center
    }
    .left_section_div {
        padding: 1.875rem;
        width: 240px;
        
    }
    .center_section_div {
        width: calc(100% - 240px);
        padding: 20px
    }
    .agenda_padding {
        padding: 20px 5px 0 5px;
    }
    .right_section_div {
        width: 370px;
    }
    /*	agenda_form*/
    .agenda_session_form_section {
        width: calc(100% - 240px);
    /*	margin-left: 240px;*/
    }
    .blue_tag_btn {
        margin-right: 18px;
        font-size: 0.875rem;
        padding: 4px 11px !important;
    }
    .blue_tag_btn > img {
        top: -37px;
        right: -27px;
        width: 52px;
    }
    .agenda_time_cards h3 {
        font-size: 1.0625rem;
    }
    .agenda_right_part_two p {
        font-size: 1rem;
    }
    .admin_left_section ul.list-unstyled li {
        font-size: 16px;
    }
    .center_top_bar .agenda_top_tabbar > li > a {
        font-size: 	1.125rem !important
    }
    .center_top_bar .agenda_top_tabbar > li > a > p {
        font-size: 14px;
    }
    .center_top_bar .agenda_top_tabbar > li {
        padding: 0 15px 10px 0 !important;
    }
    }
    
    @media screen and (min-width:1280px) and (max-width:1664px) {
    
    .left_section_div > ul > li {
        margin-bottom: 2.1875rem !important;
    }
    }
    @media screen and (max-width: 1705px) and (min-width: 1366px){
        .list:nth-child(3n) {
        margin-right: 0;
        }
        .list {
            margin-right: 24px;
        }
        .infinite-scroll-component {
            padding-right: 10px;
        }
    }
    @media screen and (min-width:1200px) and (max-width:1600px) {
    /*search-box*/
    .custom-height{
        min-height: 127px !important;
    }
    .select_arrow {
        background-position: right 20px top 24px!important;
    }
    .common_dropdown .css-yk16xz-control, .common_dropdown .css-1pahdxg-control {
        background-position: right 20px top 24px!important;
    }
    .common_dropdown .css-1fhf3k1-control{
        background-position: right 20px top 24px!important;
    }
    .css-1hwfws3, .css-g1d714-ValueContainer {
        height: 54px;
        padding: 0 20px 0 50px !important;
    }    
    .select-box-div .css-11unzgr > div {
        display: flex!important;
        align-items: center;
        padding: 0 20px 20px!important;
        font-size: 16px!important;
    }
    .assigned_tag{
        margin-bottom: 5px !important;
    }
    .agenda_time_cards {
        padding: 15px 20px;
    }
    .agenda_list_headings h3 {
        font-size: 1.375rem;
    }
    .agenda_cards_right {
        max-width: 88% !important;
        flex: 0 0 88% !important;
    }
    .agenda_cards_left {
        max-width: 12% !important;
    }
    .center_top_bar .agenda_top_tabbar > li > a {
        font-size: 	1.25rem;
    }
    .custom_full_width {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .define_room {
        margin-bottom: 30px
    }
    .left_section_div h4 {
        margin: 0 0 50px;
    }
    .center_bottom_bar .fixed_width_btn {
        margin-right: 15px;
        max-width: 210px;
    }
    .agenda_table .table thead th {
        font-size: 13px;
        padding: 15px 10px;
    }
    .agenda_table .table td {
        font-size: 12px;
        padding: 15px 10px;
    }
    .agenda_table .table thead th img {
        width: 5px;
    }
    /* .css-tlfecz-indicatorContainer {
        background-size: 16.5px;
        margin-right: 20px
    } */
    .form-custom input, .form-custom select {
        padding: 8px 20px;
        font-size: 1rem;
        height: 54px !important;
    }
    .input_eye {
        top: 17px;
        right:20px;
    }
    .form-custom .checkbox_height {
        margin: 30px 0 36px!important;
    }
    .create_post {
        font-size: 1.125rem !important;
        padding: 12px 25px !important;
        line-height: 28px !important;
    }
    .color-box{
        width: 26px;
        height: 26px;
        top: 57px;
    }
    .add_speaker .add_all_btn {
        line-height: 28px !important;
    }
    .form-custom .left_label {
        font-size: 1.125rem;
    }
    .form-custom .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
        width: 10px;
        height: 10px;
        top: 7px;
        left: 4px;
    }
    .form-custom .custom-control-label::before {
        height: 20px;
        width: 20px;
        top: 3px;
    }
    .form-custom .custom-radio .custom-control-input:checked ~ .custom-control-label::before {
        top: 3px;
    }
    .assign_btn {
        padding: 7px 25px !important;
        top: 50px;
    }
    .select-box-div img.down-arrow {
        right: 21px;
        top: 24px;
        width: 15px;
    }
    .custom-control-label::before {
        width: 18px !important;
        height: 18px !important;
    }
    .custom-control-label::after {
        width: 18px;
        height: 18px;
    }
    .agenda_table .table td img {
        height: 18px;
    }
    .agenda_table .table td > img:first-child {
        /* width: 19px; */
    }
    .speakers_heading {
        font-size: 1.25rem;
    }
    .form-custom textarea {
        line-height: 28px;
    }
    .form-custom .right_label {
        font-size: 1rem;
    }
    .attendees_user h5 {
        font-size: 18px;
    }
    .attendees_user p {
        font-size: 14px;
    }
    .speakers_card h3 {
        font-size: 1.125rem
    }
    .about_speaker h3 {
        font-size: 1.5rem;
    }
    .left_section_div > ul > li > a > span {
        font-size: 1rem;
    }
    .phone_number .select-box-div {
        top: 48px;
    }
    .speakers_card {
        margin: 0 25px 25px 0;
    }
    .banner_btns > .session-btn {
        font-size: 1.125rem;
    }
    .phone_number > input {
        padding-left: 170px !important;
    }
    .phone_number .flag-select button#select_flag_button span.flag-select__option {
        margin: 6.8px 0;
    }
    .phone_number .flag-select {
        top: 49px;
    }
    .agenda_time_cards ul > li > span {
        font-size: 0.875rem;
    }
    .agenda_time_cards .cross_icon {
        width: 22px;
    }
    .right_section_div .new_left_part h5 {
        font-size: 1.25rem;
    }
    .agenda_table {
        padding: 20px !important;
    }
    .select-box-div button {
        height: 54px !important;
        padding: 8px 20px;
        font-size: 1rem
    }
    .search{
        padding-left: 20px !important;
    }
    .css-1wa3eu0-placeholder {
        font-size: 1rem !important;
    }
    .ReactModal__Content{
        max-width: 650px;
    }
    .assign_in_tags .css-1hwfws3, .assign_in_tags .css-g1d714-ValueContainer {
        padding-left: 20px !important;
    }
    .agenda_table .table.admin_table thead th:last-child {
        width: 80px;
    }
    .agenda_table .table.user_table thead th:last-child {
        width: 110px;
    }
    }
    
    @media screen and (min-width:1200px) and (max-width:1550px) {
    .add_speaker .right-part {
        flex: 0 0 100%;
        max-width: 100%;
        padding-left: 0;
        margin-top: 20px;
    }
    .add_speaker .left-part {
        flex: 0 0 100%;
        max-width: 100%;
    }
    }
    
    @media screen and (min-width:1336px) and (max-width:1600px) {
    .blue_tag_btn {
        padding: 6px 17px !important;
        font-size: 14px
    }
    .blue_tag_btn > img {
        top: -40px;
        width: 62px;
    }
    .agenda_time_cards h3 {
        font-size: 1.125rem;
    }
    .center_top_bar .agenda_top_tabbar > li {
        padding: 0 20px 10px 0 !important;
    }
    }
    
    @media screen and (min-width:1706px) and (max-width:1920px) {
    .list:nth-child(4n) {
        margin-right: 0;
    }
    
    }
    
    @media screen and (min-width:1336px) and (max-width:1800px) {
    .left_section_div {
        padding: 20px;
        width: 250px;
    }
    .center_section_div {
        width: calc(100% - 250px);
    }
    .right_section_div {
        width: 370px;
    }
    /*	agenda_form*/
    .agenda_session_form_section {
        width: calc(100% - 250px);
    /*	margin-left: 250px*/
    }
    }
    
    @media screen and (min-width:1200px) and (max-width:1499px) {
    .list {
        width: 332px;
    }
    .fixed_section .big_btn {
        width: 46.777%;
        float: left;
    }
    .common_btn {
        font-size: 1rem !important;
        padding: 8px 16px !important;
        line-height: 28px !important
    }
    .pink_btn {
        margin: 0 0 0 10px !important
    }
    .fixed_section .blue_btn {
        margin-right: 10px
    }
    .comment-section {
    /*    height: calc(100vh - 475px) !important;*/
    }
    .left_section_div > ul > li {
        margin-bottom: 30px !important
    }
    .agenda_right_part_two {
    /*    height: calc(100vh - 385px) !important;*/
    }
    .right_section_div {
        padding: 20px;
    }
    .admin_left_section {
        padding: 40px 20px !important;
    }
    .admin_left_section ul.list-unstyled li > img {
        width: 30px
    }
    .profile_section .profile-div {
        width: 125px;
        height: 125px;
    }
    img.upload-img {
        width: 28%;
    }
    .profile-div > a > p {
        font-size: 16px;
    }
    .profile_section .profile-div {
        margin-right: 22px;
    }
    .gray_btn > img {
        margin-right: 8px;
        width: 20px;
    }
    .create_post {
        font-size: 1.125rem !important;
        padding: 12px 25px !important;
    }
    .agenda_right_section .agenda_time_cards {
        padding: 15px 15px;
    }
    .news_third_part img.width_100 {
        width: 90px;
        height: 90px;
    }
    }
    
    @media screen and (min-width:1200px) and (max-width:1799px) {
    .admin_left_section h3 {
        font-size: 22px;
    }
    .user-profile-img {
        width: 70%
    }
    }
     /* mobile-responsive */

     @media screen and (min-width: 320px) and (max-width: 374px){
        .new_left_part h5 {
            font-size: 1rem;
        }
        .wrap-login100 {
            padding: 20px 10px !important;
        }
        .reset-form .input-group-undefined {
            right: -27px;
        }
        .login100-form .custom-control label {
            margin-left: 4px;
        }

    }

    @media (max-width:767px) {
        .m-sm-none {
            display: none !important;
        }
        .m-pl-0 {
            padding-left: 0;
        }
        .m-ml-15 {
            margin-left: 15px !important;
        }
        .m-m-auto {
            margin: 0 auto;
        }
        .m-m-0 {
            margin: 0 !important;
        }
        .m-p-0 {
            padding: 0 !important;
        }
        .m-mb-10 {
            margin-bottom: 10px !important;
        }
        .m-pr-0 {
            padding-right: 0;
        }
        .m-pr-7 {
            padding-right: 7.5px !important
        }
        .m-mb-15 {
            margin-bottom: 15px;
        }
        .m-pl-7 {
            padding-left: 7.5px !important
        }
        .m-mb-25{
            margin-bottom: 25px !important;
        }
        .m-pt-15 {
            padding-top: 15px !important;
        }
        .left_section_div {
            position: fixed;
            z-index: 99;
            left: 0;
            padding: 20px 15px 20px;
            box-shadow: 0 9px 10px rgba(0,0,0,.1);
            margin-top: 10px;
            top: 47px;
            height: calc(100vh - 57px);
            overflow: auto;
        }
        .center_top_bar {
            position: fixed;
            left: 0;
            right: 0;
            padding: 15px !important;
            background-color: #f3f3fa;
            z-index: 3;
            /* top: 3px; */
            box-shadow: 0 4px 3px rgba(0, 0, 0, 0.03);
        }

        .center_section_div {
            width: 100%;
            padding: 0 15px;
            margin-top: 66px;
        }
        .mobile_nav{
            display: block;
            position: fixed;
            top: 0;
            z-index: 4;
            width: 100%;
            padding: 15px !important;
        }
        .list{
            width: 100%;
            margin: 0 0 25px 0 !important;
            padding: 15px;
        }
        .left_section_div > h4{
            display: none;
        
        }
        .new_left_part h5 {
            width: 180px;
        }
        .mobile-top-bar .css-b8ldur-Input {
            margin: 0;
            height: 50px;
        }
        .mobile-top-bar .search {
            padding-left: 15px !important;
        }
        .mobile-top-bar .assign_in_tags .css-g1d714-ValueContainer {
            padding-left: 15px !important;
        }
        .agenda_session_form_section {
            width: 100%;
        }
        .agenda_padding {
            padding: 0 15px !important;
            margin-top: 67px !important;
        }
        .mobile-top-bar .assign_in_tags .css-1hwfws3 {
            padding: 0 15px !important;
        }
        .mobile-top-bar .common_dropdown .css-yk16xz-control {
            background-position: right 18px top 22px!important;
        }
        .m-table-responsive {
            display: block;
            width: 100%;
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
            -ms-overflow-style: -ms-autohiding-scrollbar;
        }
        .left_section_div>ul>li {
            margin-bottom: 30px !important;
        }
        .left_section_div>ul>li>a>span{
            font-size: 16px
        }
        .newfeed_section {
            padding-top: 90px !important;
        } 
        .mobile_nav button.navbar-toggler {
            border: none;
            padding: 0;
        }     
        .mobile_nav button.navbar-toggler:focus, .mobile_nav button.navbar-toggler:active{
            border: none;
            outline: none;
        } 
        .list .cross_btn  {
            position: absolute;
            top: -13px;
            right: -1px;
        }
        .multiple_matches_header .cross_btn {
            position: absolute;
            top: -43px;
            right: -14px;
        }
        .speakers_heading {
            font-size: 20px;
        }
        .agenda_table {
            padding: 15px !important;
        }
        /* .left_section_div>ul>li:last-child {
            bottom: 100px;
        } */
        .multiple_matches_section {
            padding: 30px 15px 0px 15px;
        }
        .multiple_matches_section .form-group {
            margin-bottom: 15px !important;
        }
        .infinite-scroll-component {
            height: initial !important;
        }  
        .agenda_session_form_section.agenda_padding {
            padding: 0 !important;
        } 
        .ReactModalPortal .ReactModal__Content{
            padding: 0 15px !important;
        }
        form.modal_from {
            padding: 20px !important;
        }
        
        .form-custom input, .form-custom select {
            padding: 8px 15px;
            height: 50px !important;
            font-size: 1rem;
        }
        .form-custom .left_label {
            font-size: 1.125rem;
        }
        .common_dropdown .css-yk16xz-control, .common_dropdown .css-1pahdxg-control {
            background-position: right 18px top 22px !important;
        }
        .common_dropdown .css-1pahdxg-control, .common_dropdown .css-yk16xz-control {
            background-position: right 18px top 22px!important;
        }
        .assign_in_tags .css-g1d714-ValueContainer {
            padding-left: 15px !important;
            height: 50px;
        }
        .css-1hwfws3 {
            height: 50px;
        }
        .css-1g6gooi {
            height: 50px;
        }
        .css-1wa3eu0-placeholder {
            font-size: 1rem!important;
        }
        .custom-height {
            min-height: 110px!important;
        }
        .css-b8ldur-Input {
            height: 50px;
            margin: 0;
        }
        .common_dropdown .css-1fhf3k1-control {
            background-position: right 18px top 22px !important;
        }
        .color-box {
            width: 25px;
            height: 25px;
            right: 80px;
            top: 50px;
        }
        .create_post {
            padding: 6px 22px !important;
        }
        .form-custom textarea {
            padding: 15px 15px;
        }
        .assign_in_tags .css-1hwfws3, .assign_in_tags .css-g1d714-ValueContainer {
            padding-left: 15px!important;
        }
        .mobile-scrollbar-height {
            height: auto !important;
        }
        .mobile_nav .logo {
            width: 130px;
        }
        .left_section_div > ul > li:last-child {
            position: relative !important;
            bottom: auto !important;
        }
        .wrap-login100 {
            padding: 20px;
        }
        .input_eye {
            top: 16px;
            right: 15px;
        }
        .reset-form .customtooltip .customtooltiptext {
            left: 0;
            right: 0;
            margin: 0 auto;
            top: 15px !important;
        
        }
        .popup-custom-height {
            height: 80px;
        }
        .assign_btn, .common_btn {
            padding: 6px 22px!important;
        }
        .agenda_table .table thead th {
            font-size: 15px;
            padding: 15px;
        }
        
        .agenda_table .table td {
            padding: 15px 15px 0;
            font-size: 15px;
        }
        .agenda_table .table thead th img {
            display: none;
        }
        .multiple_matches_section .matches_list {
            height: auto !important;
        }
        .form-custom .checkbox_height {
            margin: 15px 0 30px!important;
        }
    }
    /*********** @media (max-width:767px) CSS End****************/
    
    @media (min-width:768px) {
        .mobile_nav {
            padding: 0 !important;  
        } 
        .navbar-expand-sm .navbar-toggler {
            display: none;
        }
        .navbar-expand-sm .navbar-collapse {
            display: -webkit-box !important;
            display: -ms-flexbox !important;
            display: flex !important;
            -ms-flex-preferred-size: auto;
            flex-basis: auto;
        }  
    }
    /*********** @media (min-width:768px) CSS End ****************/
    @media screen and (min-width:768px) and (max-width:812px) {
        .reset-form .customtooltip .customtooltiptext {
            left: 0;
            right: 0;
            margin: 0 auto;
            top: 252px !important;
        
        }
    } 

    @media only screen and (device-width: 768px) {
        .new_left_part h5 {
          font-size: 1rem;
        }
      .circules_section ul>li {
          width: 15px;
          height: 15px;
        }
    }
      
    @media screen and (min-width: 768px) and (max-width: 1199px) {
        .ipad-d-none {
            display: none !important;
        }
        .iPadlogo {
            display: block !important;
        }
        .ipad-p-0 {
            padding: 0 !important;
        }
        .ipad-pl-0 {
            padding-left: 0 !important;
        }
        .ipad-pr-0 {
            padding-right: 0 !important;
        }
        .ipad-mb-20 {
            margin-bottom: 20px !important;
        }
        .ipad-mr-10 {
            margin-right: 10px !important;
        }
        .ipad-fs-15 {
            font-size: 15px !important;
        }
        .left_section_div>ul>li {
            justify-content: center;
        }
        .left_section_div>ul>li>a>img {
            padding-right: 0;
        }
        .left_section_div>ul>li>a>p, .left_section_div>ul>li>a>span {
            display: none;
        }
        .center_section_div {
            width: calc(100% - 100px);
            padding: 15px;
        }
        .left_section_div {
            width: 100px;
            padding: 15px;
        }
        .search {
            padding-left: 20px !important;
            padding-right: 5px !important;

        }
        .css-b8ldur-Input {
            margin: 0;
            height: 58px !important;
        }
        .css-1hwfws3, .css-g1d714-ValueContainer {
            height: 58px;
        }
        .assign_in_tags .css-1hwfws3, .assign_in_tags .css-g1d714-ValueContainer {
            padding-left: 20px !important;
        }
        .common_dropdown .css-yk16xz-control, .common_dropdown .css-1pahdxg-control {
            background-position: right 20px top 25px !important;
        }
        .search .form-control {
            padding: 7px 10px 7px 10px;
            color: #8D93A7;
            border: none;
        }
        .center_top_bar .common_btn {
            margin-left: 15px !important;
        }
        .center_top_bar {
            margin-bottom: 15px;
        }
        .agenda_session_form_section {
            width: calc(100% - 100px);
        }
        /* .cross_btn {
            top: -40px;
            right: -16px;
        } */
        .left_section_div > ul > li:last-child {
            right: 35px;
        }
        .form-custom .left_label {
            font-size: 16px;
        }
        .center_top_bar .agenda_session_tabbar .common_btn {
            margin-left: auto !important;
        }
        .agenda_padding {
            padding: 20px 5px 0 5px;
        
        }
        .color-box {
            width: 25px;
            height: 25px;
            right: 80px;
            top: 57px;
        }
        .agenda_table {
            padding: 20px !important;
        }
        .agenda_table .table thead th {
            font-size: 14px;
            padding: 15px;
        }
        .agenda_table .table td {
            padding: 15px 15px 0 15px;
        }
        .multiple_matches_section {
            padding: 30px 20px 0px 20px;
        }
        .multiple_matches_section .matches_list {
            margin: 0 0 20px!important;

        }
        .multiple_matches_section .matches_list {
            height: auto !important;
        }
        
    }

    @media only screen and (min-device-width : 375px) and (max-device-width : 812px) and (-webkit-device-pixel-ratio : 3) { 
        .left_section_div>ul>li:last-child {
            position: relative;
            bottom: auto;
            margin: 20px !important;
            right: auto !important;
        }
    }

    @media (min-width: 1200px) {
        .xl-none {
            display: none;
        }
    }

    @media (max-width:1200px) {
        .upload-img-div {
            margin-top: 30px !important;  
        }   
    }

    @media (min-width: 1366px) {
        .multiple_matches_section .matches_list {
            padding:20px;
        }
        .multiple_matches_section .blue_tag_btn.matches_dropdown {
            padding: 20px;
        }
        
    }

@media (max-width: 767px) {
    .newfeed_section {
        padding-top: 143px !important;
    }
}
