span.avtar_text {
    width: 100px;
    height: 100px;
    margin: 0 auto;
    background-color: #4aa546;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: #fff;
    display: flex;
    text-transform: uppercase;
    font-size: x-large;
}

span.avtar_text_big {
    width: 194px;
    height: 194px;
    justify-content: center;
    background-color: #4aa546;
    margin: 0 auto;
    border-radius: 50%;
    color: #fff;
    font-size: 36px;
    align-items: center;
    text-transform: uppercase;
    display: flex;
}

span.avtar_medium {
    width: 80px;
    height: 80px;
    background-color: #4aa546;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    display: flex;
    text-transform: uppercase;
    margin: 0 auto;
    color: #fff;
    font-size: 24px;
}

/* .speakers_card > img{
	width: 80px;
    height: 80px;
} */

.about_speaker > img{
    width: 194px;
    height: 194px;
}

.my_pagination > li a.page-link {
    border: none;
    margin-right: 13px;
    height: 32px;
    width: 32px;
    padding: 0;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 4px;
    font-size: 16px;
    color: #c0eabe !important;
}

.my_pagination > li a.page-link > img.left-arrow {
    width: 12px;
    transform: rotate(90deg);
}
.my_pagination > li a.page-link > img.right-arrow {
    width: 12px;
    transform: rotate(-90deg);
}

.my_pagination > li a.page-link:focus, .my_pagination > li a.page-link:hover {
    box-shadow: none;
	background-color: #fff !important;
}

.my_pagination > li:last-child a.page-link{
	margin: 0
}

.my_pagination .page-item.active .page-link{
	background-color: #4aa546 !important;
    border-color: #4aa546 !important;
	color: #fff !important;
}
/* on User listing page ,pagination t label CSS  */
.paginationText{
    font-size: 20px;
}
.pagination_section{
    justify-content: flex-end;
}
/*-----------------end------------ */
/* Admin profile Email address   */
span.dot_class {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
/*-----------------end------------ */
/* add cursor pointer on attendee page */
.cursor_pointer {
    cursor: pointer;
}

.control-dots {
    padding: 0 !important;
}    

/*-----------------calendar------------ */
.react-datepicker__day--selected, .react-datepicker__header{
    background-color: #4aa546 !important;
   
}
.react-datepicker__day-name, .react-datepicker__current-month, .react-datepicker__current-year{
    color: #fff !important;
}
.react-datepicker__triangle{
    border-top-color: #4aa546 !important;
    border-bottom-color: #4aa546 !important;
}
.react-datepicker__navigation--next{
    border-left-color:  #fff !important;
}

.react-datepicker__navigation--previous {
    border-right-color:  #fff !important;
}
/*-----------------end------------ */

  /* width */
::-webkit-scrollbar {
width: 0px;
border-radius: 20px;

}

/* Track */
::-webkit-scrollbar-track {
background: #f3f3fa; 
border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
background: #f3f3fa; 
}

::-moz-scrollbar {
width: 0px;
border-radius: 20px;

}

/*/ Track /*/
::-moz-scrollbar-track {
    background: #f3f3fa; 
    border-radius: 10px;
}

/*/ Handle /*/
::-moz-scrollbar-thumb {
    background: #f3f3fa; 
}

.setting_pages .fr-wrapper {
    border-radius: 0 0 10px 10px;
    border: 1px solid #ccc !important;
}

.setting_pages .second-toolbar {
    display: none;
}

input[type=checkbox]{
    -ms-transform: scale(1.5); /* IE */
    -moz-transform: scale(1.5); /* FF */
    -webkit-transform: scale(1.5); /* Safari and Chrome */
    -o-transform: scale(1.5); /* Opera */
    transform: scale(1.5);
    padding: 10px;
}