@charset "utf-8";
/* CSS Document */
@font-face
{
    font-family: 'CircularStd-Book';
    src:url('../fonts/CircularStd-Book.ttf') format('truetype');
/*
    font-weight: normal;
    font-style: normal;
*/
}
@font-face {
    font-family: 'CircularStd-Bold';
    src:url('../fonts/CircularStd-Bold.ttf') format('truetype');
/*
    font-weight: normal;
    font-style: normal;
*/
}
@font-face {
    font-family: 'Gelion-Regular';
    src:url('../fonts/Gelion-Regular.ttf') format('truetype');
/*
    font-weight: normal;
    font-style: normal;
*/
}