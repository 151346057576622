html, body {
    height: 100%;
}
html{
     -webkit-font-smoothing: antialiased;
}
body {
    position: relative;
    background-color: #F3F3FA;
    /*    overflow: hidden;*/
    font-family: 'CircularStd-Book' !important;
    color: #2C2D34;
    -webkit-font-smoothing: antialiased;
}
.word_break{
    word-break: break-word;
}
a:hover {
    text-decoration: none !important
}
input {
    outline: none
}
.mb-30{
	margin-bottom: 30px
}
.space-bw {
    justify-content: space-between
}
.justify-center {
    justify-content: center;
}
.vs {
    font-family: "CircularStd-Bold"
}
.color-gray {
    color: #c0eabe
}
.color-light {
    color: #f3f3fa
}
.ml-10{
    margin-left: 10px
}
.mb-20 {
    margin-bottom: 20px
}
.flex1 {
    flex: 1
}
.pl-7 {
    padding-left: 7.5px !important
}
.relative {
    position: relative;
}
.pr-7 {
    padding-right: 7.5px !important
}
.pl-3-5 {
	padding-left: 3px !important
}
.pr-3-5{
	padding-right: 3px !important
}
.ml-7 {
    margin-left: 7.5px !important
}
.mr-7 {
    margin-right: 7.5px !important
}
.ml-3-5 {
    margin-left: 3px !important
}
.mr-3-5{
    margin-right: 3px !important
}
.mt-40 {
    margin-top: 40px !important
}
.mt-30 {
    margin-top: 30px
}
.mr-20 {
    margin-right: 20px
}
.width_height_100{
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    max-width: 100%;
    height: auto;
    width: 100%
}
/*.width_height_100{
    height: 100% !important;
    width: 100%
}*/
.display-grid{
    display: inline-grid;
}
.border-radius-full {
    border-radius: 50%
}
.align-flexstart {
    align-items: flex-start
}
.align-item-end{
    align-items: end;
}
.align-baseline {
    align-items: baseline;
}
.form-control:focus {
    color: #8D93A7 !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
input:focus, input:active, select:focus, select:active {
    border: none;
    box-shadow: none !important
}
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #8d93a7!important;
}
::-moz-placeholder { /* Firefox 19+ */
  color: #8d93a7!important;
}
:-ms-input-placeholder { /* IE 10+ */
  color: #8d93a7!important;
}
:-moz-placeholder { /* Firefox 18- */
  color: #8d93a7!important;
}
.select_arrow{
    background-image: url('../images/arrow.svg')!important;
    background-repeat: no-repeat !important;
    background-position: right 30px top 25px !important;
}
.border-10 {
    border-radius: 10px
}
.align-center {
    align-items: center
}
.p-30 {
    padding: 1.875rem
}
.pt-30 {
    padding-top: 1.875rem
}
.p-40 {
    padding: 2.5rem
}
.big_btn {
    width: 100%;
    font-size: 1.25rem !important
}
.pink_btn {
    background-color: #ffe5e5;
    color: #DF2F2F
}
.fixed_width_btn {
    max-width: 325px
}
.assign_btn {
    background: #DBDBFF;
    border-radius: 3px !important;
    padding: 10px 25px !important;
    color: #4aa546;
    position: absolute;
    right: 22px;
    top: 53px;
}
.common_btn {
    font-family: Gelion;
    font-size: 1.125rem !important;
    line-height: 36px !important;
    border-radius: 5px;
    padding: 10px 25px !important;
    font-family: 'CircularStd-Bold';
}
.blue_btn {
    background-color: #4aa546;
    color: #fff;
}
.left_section_div {
    width: 300px;
    background-color: #fff;
    position: relative;
    padding: 30px;
    height: 100vh;
    overflow : auto;
/*	position: fixed*/
}
.left_section_div h4 {
    margin: 0 0 90px;
    text-align: center;
    font-size: 1.25rem;
}
.left_section_div > ul > li {
    font-size: 21px;
    line-height: 27px;
    letter-spacing: -0.86px;
    display: flex;
}
.left_section_div > ul > li > a {
    color: #8D93A7;
    /* display: flex */
}
.left_section_div > ul > li.active > a {
    color: #2C2D34
}
.left_section_div > ul > li:last-child {
    position: absolute;
    bottom: 40px;
    margin: 0 !important;
}
.left_section_div > ul > li.active:after {
    color: #2C2D34;
    border-right: 2px solid #4aa546;
    position: absolute;
    content: "";
    height: 29px;
    right: 0
}
.left_section_div > ul > li > a > img {
    padding-right: 20px
}
.left_section_div > ul > li > a > span {
    padding-top: 3px;
    font-size: 1.25rem;
}
.left_section_div > ul > li > a > p {
    padding-top: 3px;
    font-size: 1.25rem;
    margin-bottom: 0
}
.right_section_div {
    width: 434px;
    background-color: #fff;
    height: 100vh
}
.center_section_div {
    width: calc(100% - 300px);
}
.cross_btn {
    position: absolute;
    top: -9px;
    right: -6px;
}
.center_top_bar .list-unstyled > li {
    display: inline;
    flex: 1;
    text-align: center;
    padding-bottom: 10px;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 30px;
    border-bottom: 1px solid #D9D9D9;
}
.center_top_bar .list-unstyled > li.active {
    border-bottom: 2px solid #4aa546;
    font-family: 'CircularStd-Bold';
}
.center_top_bar .list-unstyled > li > a {
    color: #2C2D34;
    display: block;
    padding-bottom: 10px;
}
.center_top_bar .list-unstyled>li>label {
    display: initial;
    margin: 0 !important;
}
.search {
    background-color: #fff;
    border-radius: 5px;
    padding-left: 30px !important
}
.search .form-control {
    padding: 7px 30px 7px 15px;
    color: #8D93A7;
    border: none;
}
.newfeed_list li {
    width: 360px;
    background-color: #fff;
    padding: 20px;
    border-radius: 5px
}
.list {
    width: 360px;
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 20px;
    display: inline-block;
    vertical-align: top;
    margin-right: 19px;
    text-align: left;
	height: 301px;
    border: 1px solid #fdfdfd;
}
.list.active {
    border: 1px solid #4AA546;
}
.list:hover {
    border: 1px solid #4aa546;
}
.new_left_part img {
    border-radius: 50%;
    width: 51px;
    height: 51px;
    margin-right: 10px
}
.news_third_part_one {
    height: 150px;
    overflow: hidden;
    border-radius: 10px;
}
.news_third_part_two{
	height: 98px;
    overflow: hidden;
    border-radius: 10px;
}
.news_third_part_four{
	height: 191px;
    overflow: hidden;
    border-radius: 10px;
}
.news_third_part_five > div{
	height: 98px;
	overflow: hidden;
    border-radius: 10px;
}
.news_third_part_three > div{
	height: 98px;
	overflow: hidden;
    border-radius: 10px;
}
.user-sm-img {
    border-radius: 50%;
    width: 51px;
    height: 51px;
}
.new_left_part h5 {
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 24px;
    font-family: 'CircularStd-Bold';
    /* text-overflow: ellipsis; old*/
    white-space: nowrap;
    overflow: hidden;
    /* width: 135px;  old*/
    width: 200px;
}
.new_left_part p {
    color: #c0eabe;
    font-size: 1rem;
}
.new_second_part p {
    font-size: 1rem;
    line-height: 22px;
    color: #2C2D34;
    text-align: justify;
}
.match-type p {
    font-size: 1rem;
    line-height: 22px;
    color: #d5c825;
    text-align: justify;
}
.new_second_part_one > p{
	text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.news_third_part .carousel-slider .slider-wrapper .slider .slide {
    background-color: transparent;
}
.news_third_part .carousel-slider .slider-wrapper .slider .slide div img {
    border-radius: 10px;
}
.news_third_part .carousel-slider .slider-wrapper{
    border-radius: 10px;
}
.right_section_div .new_left_part h5 {
    font-size: 1.375rem;
    width: auto;
    text-overflow: initial;
    overflow: inherit;
    white-space: inherit;
}
.news_third_part > img {
    border-radius: 10px;
}
img.width_100 {
    width: 100px;
	height: 100px
}
.comment-section {
    border-top: 1px solid #EEF0F4;
    padding-top: 30px
}
.comment-box {
    background: #F6F6F6;
    border-radius: 10px 10px 10px 0px;
    padding: 8px 10px;
    color: #2C2D34;
}
.comment_heading {
    font-size: 1rem;
    font-family: 'CircularStd-Bold';
	padding-right: 25px
}
.center_top_bar {
    margin-bottom: 30px
}
.comment_text {
    font-size: 0.875rem;
}
.newfeed_section {
    justify-content: space-between;
    text-align: center
}
.close-icon {
    width: 23px
}
/*****************************checkbox*****************************/
.switch {
    position: relative;
    display: inline-block;
    width: 41px;
    height: 22px;
    margin: 2px 10px 0 0
}
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}
.switch.small {
    width: 33px;
    height: 19px;
}
.slider.small:before {
    height: 15px;
    width: 15px;
}
input:checked + .slider.small:before {
    -webkit-transform: translateX(14px);
    -ms-transform: translateX(14px);
    transform: translateX(14px);
}
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #f4cac7;
    -webkit-transition: .4s;
    transition: .4s;
}
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #c0eabe;
    -webkit-transition: .4s;
    transition: .4s;
}
.slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 2px;
    bottom: 2px;
    background-color: #ffffff;
    -webkit-transition: .4s;
    transition: .4s;
}
input:checked + .slider {
    background-color: #4aa546;
}
input:focus + .slider {
/*    box-shadow: 0 0 1px #2196F3;*/
}
input:checked + .slider:before {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
    background-color: #ffffff;
}
/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}
.slider.round:before {
    border-radius: 50%;
}
.big .slider:before {
    width: 21px;
    height: 21px;
    left: 4px;
    bottom: 3px;
}
label.switch.big {
    height: 27px;
    width: 49px;
}
input:checked + .big_slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
}
.left_section_div .logo {
    width: 90%;
    /* margin-right: 10px; */
}
/*****************************checkbox-end*****************************/
.comment-reaction > div > a {
    font-size: 1rem;
    color: #2C2D34;
}
.comment-reaction > div > a > img {
    margin-right: 10px
}
.comment-box .close-icon {
    position: absolute;
    content: "";
    right: 10px;
    top: 10px;
    width: 14px;
}
.comment-box {
    position: relative;
}
.fixed_section {
    position: absolute;
    bottom: 0px;
    width: 100%;
    padding: 20px 30px 20px;
    background-color: #fff
}
.edit-hide .pink_btn {
    width: 100% !important;
    margin: 0 !important;
}
.comment-section {
/*    height: calc(100vh - 530px) !important;*/
    overflow: auto;
    display: block !important;
    padding-bottom: 40px
}
/*****************************agenda_start*****************************/
.center_top_bar .agenda_top_tabbar > li.active {
    border: none !important;
}
.center_top_bar .agenda_top_tabbar > li {
    font-family: 'CircularStd-Bold';
    border: none;
    padding-bottom: 0!important;
}
.agenda_top_tabbar .active a {
    color: #000 !important;
}
.center_top_bar .agenda_top_tabbar > li > a > p {
    font-size: 1rem;
    color: #c0eabe !important;
}
.center_top_bar .agenda_top_tabbar > li > a {
    font-size: 1.5rem;
    color: #c0eabe;
    padding-bottom: 0 !important;
}
.agenda_list {
    margin-bottom: 35px
}
.agenda_list h3 {
    font-size: 1.5rem
}
.agenda_list > a {
    color: #4aa546;
    font-size: 1.375rem
}
.agenda_list_headings {
    font-family: 'CircularStd-Bold';
    margin: 0 0 30px 0
}
.agenda_time_cards {
    background-color: #fff;
    padding: 15px 28px;
    border-radius: 10px;
}
.agenda_cards .agenda_time_cards.active {
    background: #4aa546;
    color: #fff
}
.agenda_cards_left {
    display: grid;
}
.agenda_cards_left p.last_date {
    align-self: flex-end
}
.agenda_cards_right:before, .agenda_cards_right:after {
    position: absolute;
    content: "";
    width: 100%;
    right: 0;
    left: 0;
}
.agenda_cards_right:before {
    border-top: 1px dashed #B7B7C5;
    top: 9px;
}
.agenda_cards_right:after {
    border-bottom: 1px dashed #B7B7C5;
    bottom: 9px;
}
.agenda_cards_right {
    padding: 10px 0 13px !important;
}
.agenda_time_cards ul > li {
    display: inline
}
.agenda_time_cards ul > li > img {
    padding-right: 10px
}
.agenda_time_cards ul > li {
    padding-right: 20px;
    align-items: center;
    display: inline-flex;
}
.agenda_time_cards ul > li:last-child {
    padding-right: 0
}
.agenda_time_cards h3 {
    font-size: 1.25rem;
    margin-bottom: 15px;
    font-family: 'CircularStd-Bold';
	text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.agenda_cards_left {
    max-width: 10% !important;
}
.agenda_cards_right {
    max-width: 90% !important;
    flex: 0 0 90% !important;
}
.agenda_time_cards ul > li > span {
    font-size: 1rem;
    color: #c0eabe;
}
.agenda_time_cards.active ul > li > span {
    color: #fff;
}
.agenda_cards_right_row {
    margin: 0 !important
}
.agenda_right_part_one h3 {
    font-size: 1.25rem;
    line-height: 36px;
    font-family: 'CircularStd-Bold';
}
.agenda_right_part_two img {
    width: 22px;
    margin-right: 15px;
    /* margin-top: 6px; */
}
.agenda_right_part_two p {
    margin: 0;
    font-size: 1.0625rem;
    flex: 1
}
.agenda_right_part_two {
/*
    height: calc(100vh - 480px) !important;
    overflow: auto;
*/
}
.tag_btn {
    background: #EAEAF5;
    border-radius: 5px !important;
    padding: 8px 19px;
    color: #4aa546;
    margin-right: 13px;
    margin-bottom: 10px;
}
.tag_btn:last-child {
    margin-right: 0;
}
.tag_btn_divider {
    margin-bottom: 15px
}
/*****************************agenda_session*****************************/
.agenda_session_form_section .center_top_bar .list-unstyled > li {
    flex: initial;
    padding: 0 40px 10px;
}
.agenda_padding {
    padding: 30px 15px 0 15px;
/*	margin-left: 300px*/
}
.agenda_session_form_section {
    width: calc(100% - 300px);
}
.agenda_session_tabbar ul {
    justify-content: center
}
.form-custom .left_label {
    font-size: 1.25rem;
}
.form-custom .right_label {
    font-size: 1.125rem;
    color: #8D93A7
}
.form-custom textarea {
    border: none;
    resize: none;
    padding: 20px 30px;
    line-height: 30px
}
.form-custom input, .form-custom select {
    background: #FFFFFF;
    border-radius: 3px;
    padding: 17px 30px;
    border: none;
    color: #8D93A7;
    font-size: 1.125rem;
    height: 60px !important;
}
.color-box{
    width: 29px;
    height: 29px;
    border-radius: 3px;
    position: absolute;
    right: 100px;
    top: 61px;
    z-index: 1
}
/*.form-custom {
    margin-bottom: 0px!important;
    height: 127px;
}
.auto_height{
    margin-bottom:  30px !important;
    height: auto !important 
}*/
.phone_number .flag-select{
    padding: 0;
    position: absolute;
    top: 51px;
    background-color: #DBDBFF;
    left: 21px;
}
.phone_number .flag-select button#select_flag_button {
    padding: 2px 15px 2px 0;
}
.phone_number .flag-select .flag-select__btn:focus{
    outline: none !important;
}
/* .phone_number .flag-select button#select_flag_button {
    padding-left: 0;
    padding-right: 15px;
} */
.phone_number .flag-select button#select_flag_button span.flag-select__option {
    margin: 9px 0;
    padding: 0 8px;
}
.phone_number .flag-select .flag-select__btn .flag-select__option > img, .phone_number .flag-select .flag-select__option span > img{
    top: auto !important;
}
.phone_number .flag-select .flag-select__btn, .phone_number .flag-select .flag-select__btn .flag-select__option{
    align-items: center;
    display: flex;
}
.span.flag-select__option__label {
    color: #4aa546;
}
.flag-select__btn:before{
    background: url(../images/blue_arrow.svg) right no-repeat;
    right: 8px
}
.flag-select__btn:after{
    border: none !important
}
.form-custom {
    margin-bottom: 30px!important;
}
.custom-height {
    min-height: 130px !important;
    margin-bottom: 0 !important;
}
.popup-custom-height {
    margin: 0 !important;
    height: 90px;
}
.form-custom select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}
.form-custom .custom-control-label::before {
    height: 1.5rem;
    width: 1.5rem;
    background-color: transparent;
    border: 2px solid #8D93A7;
    top: 0px;
}
.form-custom .custom-control-label {
    font-size: 1.125rem;
    color: #8D93A7;
    padding-left: 15px;
}
.form-custom .custom-radio .custom-control-input:checked ~ .custom-control-label::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    border: 2px solid  #8D93A7;
    background: #fff0 !important;
    box-shadow: none;
}
.form-custom .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
    content: '';
    width: 14px;
    height: 14px;
    background: #8D93A7;
    position: absolute;
    top: 5px;
    left: 5px;
    border-radius: 100%;
    background-image: none !important;
}
.form-custom .banner_img {
    /* max-height: 200px; */
    /* overflow: hidden; */
    border-radius: 10px;
    position: relative;
    background: url('../images/image.svg') no-repeat center #fff;
    height: 204px;
}
/* .form-custom .banner_img > img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    max-width: 100%;
    height: auto;
    width: 100%;
} */
.form-custom .banner-full-img > img {
    border-radius: 10px;
    height: 204px;
    max-width: 100%;
}
.banner_btns > .session-btn {
    font-size: 1.25rem;
}
.banner_btns > .session-btn.delete {
    color: #DF2F2F;
    padding-left: 40px;
    position: relative;
}
.banner_btns > a.delete:before {
    content: "";
    position: absolute;
    border-left: 1px solid #A2A2A8;
    width: 1px;
    height: 26px;
    left: 18px;
}
.blue_tag_btn {
    background: #4aa546;
    border-radius: 5px !important;
    padding: 8px 30px !important;
    color: #ffffff;
    margin: 0 15px 15px 0;
    position: relative;
}
.blue_tag_btn:last-child {
    margin-right: 0
}
.blue_tag_btn > img {
    position: absolute;
    top: -42px;
    right: -32px;
}
/**/

/*****************************agenda-speaker-start*****************************/
.speakers_card {
    width: 200px;
    background: #FFFFFF;
    border-radius: 10px;
    margin: 0 30px 30px 0;
    padding: 20px;
    position: relative
}
.speakers_card.active {
    border: 1px solid #4aa546;
}
.speakers_heading {
    font-size: 1.375rem;
}
.speakers_card h3 {
    font-size: 1.25rem;
    margin: 10px 0 15px 0
}
.speakers_card > a > span {
    color: #4aa546;
    margin-left: 8px
}
.speakers_card > a > img {
    height: 21px
}
.speakers_card > a {
    display: flex;
    justify-content: center;
}
.cross_icon {
    position: absolute;
    right: 10px;
    top: 10px;
}
.speakers_card .cross_icon {
    width: 26px;
}
.add_speaker .add_all_btn {
    padding: 12px 16px !important;
    color: #4aa546;
}
.tennis_dropdown button {
    width: 100%;
    font-size: 18px;
    line-height: 45px;
    padding: 7px 30px 7px 30px;
    background-color: #fff;
    color: #c0eabe;
    border: none;
    text-align: left;
}
.tennis_dropdown button:hover, .tennis_dropdown button:active {
    background-color: #fff !important;
    color: #c0eabe !important;
    border: none !important;
    box-shadow: none !important
}
.tennis_dropdown button:after {
    content: none
}
.agenda_right_section {
    padding: 30px 20px 10px 20px
}
.about_speaker h3 {
    font-size: 1.75rem;
    font-family: 'CircularStd-Bold';
}
.agenda_right_section .agenda_time_cards {
    background-color: #f3f3fa;
    padding: 15px 21px;
    position: relative;
    margin-bottom: 15px
}
.agenda_right_section .agenda_time_cards h3 {
    font-size: 1.125rem;
    margin-bottom: 8px;
    font-family: 'CircularStd-Bold';
	text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 260px;
}
.agenda_time_cards .cross_icon {
    width: 24px;
}
.session_list .session_heading {
    font-size: 20px;
}
/**/
/*reset-passwod-start*/
.input_eye{
    position: absolute;
    top: 21px;
    right: 30px;
}
.reset-form .customtooltip:hover .customtooltiptext {
    visibility: visible;
}
.reset-form .customtooltip .customtooltiptext {
    visibility: hidden;
    width: 320px;
    height: 140px;
    background-color: #ffffff;
    color: #4aa546;
    text-align: left;
    border-radius: 6px;
    padding: 10px 0;
    position: fixed;
    z-index: 1;
    box-shadow: 0 10px 50px rgba(0,0,0,.1);
}
.reset-form .input-group-undefined .button {
    border: 1px solid #4aa546;
    border-radius: 50%;
    background-color: #4aa546;
    height: 22px;
    width: 22px;
    color: #ffffff;
    line-height: 1.4;
    margin: 0 !important;
    text-align: center;
}
.reset-form .input-group-undefined {
    position: absolute;
    right: -35px;
    top: 19px;
}
/*reset-passwod-end*/
/*login-start*/
.login_page {
    width: 100%;
    min-height: 100vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.wrap-login100 {
    width: 450px;
    border-radius: 10px;
    position: relative;
    background-color: #fff;
    padding: 30px;
}
.login100-form-title {
    font-size: 30px;
    line-height: 1.2;
    text-transform: uppercase;
    text-align: center;
    width: 100%;
    display: block;
    margin-bottom: 40px
}
.login100-form .form-custom input, .login100-form .form-custom select {
    background: #f3f3fa;
}
.login100-form .custom-control label {
    margin-left: 10px;
    color: #50624abf;
}
.login100-form .custom-control {
    /* padding-top: 3px !important;
    padding-left: 30px !important; */
}
.login-logo > img {
    width:60%;
    margin-bottom: 10px;
    max-width: 100%;
}
.login-logo p {
    font-size: 24px
}

.back_to_login{
    margin-top: 5px
}
.label-checkbox100 {
    font-size: 16px;
    color: #999999;
    line-height: 1.2;
    display: block;
    position: relative;
    padding-left: 26px;
    cursor: pointer;
}
.label-checkbox100::before {
    content: "✔";
    font-size: 13px;
    color: transparent;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 18px;
    height: 18px;
    border-radius: 3px;
    border: 2px solid #4aa546;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}
.input-checkbox100:checked + .label-checkbox100::before {
    color: #4aa546;
}
.input-checkbox100 {
    display: none;
}
.gray_btn > img {
    margin-right: 10px
}
.gray_btn {
    background-color: #c0eabe;
    color: #42943f;
}
.agenda_table {
    padding: 30px 30px 30px 30px !important;
    background-color: #fff;
    border-radius: 10px;
}
.center_bottom_bar .fixed_width_btn {
    margin-right: 30px
}
.center_bottom_bar .fixed_width_btn:last-child {
    margin-right: 0
}
.agenda_table .table-bordered th, .agenda_table .table-bordered td, .agenda_table .table-bordered {
    border: none !important
}
.agenda_table .table-bordered thead tr {
    background: #F3F3FA;
    border-radius: 3px;
}
.agenda_table .table thead th {
    font-size: 1rem;
    padding: 20px;
}
.agenda_table .table td:last-child > a {
    vertical-align: middle;
    display: inline-block;
}
.agenda_table .table td {
    color: #8D93A7;
    padding: 20px 20px 0 20px;
    vertical-align: middle;
}
.agenda_table .table.admin_table thead th:last-child {
    width: 107px;
}
.agenda_table .table.user_table thead th:last-child {
    width: 135px;
}
/* .agenda_table .table td:last-child {
    display: flex;
    justify-content: space-between;
} */

.form-custom .checkbox_height {
    margin: 30px 0 40px 0 !important;
}
.forget {
    font-family: 'CircularStd-Bold';
}
.attendees_list {
    background-color: #fff;
    border-radius: 10px;
    padding: 30px 0 0
}
.attendees_user img {
    border-radius: 50%;
    width: 44px;
    height: 44px;
    margin-right: 12px;
}
.show_hide {
    position: absolute;
    top: 21px;
    right: 30px;
}
/**/

/*****************************Attendees-start*****************************/

.attendees_user {
    margin: 0 27px 40px;
}
.attendees_user p {
    color: #4aa546;
    font-size: 16px;
}
.attendees_user h5 {
	font-size: 20px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 114px;
}
.post-image .post_square_img {
    border-radius: 3px;
    width: 100%
}
.post-image .post_cancel {
    position: absolute;
    right: -15px;
    top: -41px;
}

.player_cancel {
    position: absolute;
    top: -44px;
    right: 158px;
    /* top: -111px; */
}
/**/

/*****************************admin-start*****************************/
.white_bg {
    background-color: #fff;
    border-radius: 10px
}
.admin_left_section {
    padding: 60px 30px !important
}
.admin_left_section ul.list-unstyled li {
    color: #c0eabe;
    font-size: 18px;
    margin-bottom: 40px !important;
    display: flex;
}
.admin_left_section ul.list-unstyled li:last-child {
    margin-bottom: 0 !important;
}
.admin_left_section h3 {
    font-size: 28px;
    font-family: 'CircularStd-Bold';
    margin-bottom: 5px;
}
.admin_left_section p {
    font-size: 20px;
    margin-bottom: 40px
}
.admin_right_section {
    padding: 30px !important
}
.admin_right_section > h4 {
    font-size: 1.375rem;
    margin-bottom: 30px
}
.admin_right_section .form-custom input {
    background: #f3f3fa;
}
.profile_section .profile-div {
    width: 180px;
    height: 180px;
    border-radius: 3px;
    background-color: #f3f3fa;
    margin-right: 30px;
    display: flex;
    align-items: center;
}
.profile-div .post_cancel {
    position: absolute;
    top: -42px;
    right: -33px;
}
.profile-div > a > p {
    margin-top: 15px;
    font-size: 18px
}
.phone_number > input {
    padding-left: 170px
}
.phone_number .flag-select ul .filterBox input {
    padding: 6px;
    height: auto !important;
    margin: 0;
    width: 100%;
    text-align: left;
}
.select-box-div img.down-arrow {
    position: absolute;
    right: 30px;
    top: 26px;
}
.phone_number .select-box-div img.down-arrow {
    position: absolute;
    right: 12px;
    top: 19px;
}
.phone_number .select-box-div select {
    padding: 10px 10px;
    height: auto !important;
    width: 69px;
    background-color: #DBDBFF;
    color: #4aa546;
}
.phone_number .select-box-div .form-control:focus {
    color: #4aa546 !important;
}
.phone_number .select-box-div {
    position: absolute;
    width: auto;
    top: 52px;
    left: 22px;
}
.setting_pages .main-heading {
    font-size: 28px;
    margin-bottom: 30px
}
.setting-categories .blue_tag_btn {
    padding: 8px 62px !important;
    margin: 0 45px 30px 0;
}
.setting-categories .blue_tag_btn > img.edit {
    right: 6px;
}
.setting-categories {
    padding: 0 15px
}
.transform_card {
    margin: 8px 0 5px 0;
}
.speakers_card .blue_tag_btn {
    margin-bottom: 0;
}
.agenda_padding .center_top_bar .list-unstyled > li {
    flex: initial;
    padding: 0 40px 0;
}
.center_top_bar .agenda_top_tabbar > li {
    padding: 0 50px 15px 0 !important;
    display: inline-block;
}
.about_speaker > img{
	width: 194px;
	height: 194px;
}
span.contact_btn {
    width: 50px;
    height: 50px;
    background-color: #4aa546;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: #fff;
    display: flex;
}
.contact_big {
    width: 194px;
    height: 194px;
    justify-content: center;
    background-color: #4aa546;
    margin: 0 auto;
    color: #fff;
    font-size: 36px;
}
.speakers_card > img.border-radius-full{
	width: 80px;
    height: 80px;
}
.contact_medium{
	width: 80px;
    height: 80px;
    justify-content: center;
    background-color: #4aa546;
    margin: 0 auto;
    color: #fff;
    font-size: 24px;
}
/*pagination-css*/
.my_pagination > li a.page-link {
    border: none;
    margin-right: 13px;
    height: 32px;
    width: 32px;
    padding: 0;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 4px;
    font-size: 16px;
    color: #c0eabe;
}
.my_pagination > li a.page-link > img.left-arrow {
    width: 12px;
    transform: rotate(90deg);
}
.my_pagination > li a.page-link > img.right-arrow {
    width: 12px;
    transform: rotate(-90deg);
}
.my_pagination > li a.page-link:focus, .my_pagination > li a.page-link:hover {
    box-shadow: none;
	background-color: #fff
}
.my_pagination .page-item.active .page-link{
	background-color: #4aa546;
    border-color: #4aa546;
	color: #fff;
}
.my_pagination > li:last-child a.page-link{
	margin: 0
}
/*popup-css*/
.modal_from .form-custom input {
    background: #F6F6F6;
}
h2.modal_heading {
    font-size: 30px;
    margin-bottom: 35px;
}
.ReactModal__Overlay {
    background-color: rgba(0, 0, 0, 0.2) !important;
    z-index: 999;
    overflow-x: hidden;
    overflow-y: auto;
}
.ReactModal__Content {
    padding: 40px !important;
    max-width: 740px;
    top: 50% !important;
    left: 50% !important;
    bottom: auto !important;
    right: auto !important;
    transform: translate(-50%, -50%);
    margin: 0 auto;
}

/******************************custom checkbox***********************************************/
.select-box-div button {
    width: 100%;
    box-shadow: none;
    border-radius: 3px;
    font-size: 1.125rem;
    height: 60px !important;
    padding: 17px 30px;
/*     / background: url(../images/arrow.svg);  /*/
    color: #c0eabe;
}
.css-1jllj6i-control {
    display: none !important;
}
.select-box-div .css-11unzgr > div {
    display: flex !important;
    align-items: center;
    padding: 0 30px 20px !important;
    color: #c0eabe !important;
    font-size: 18px !important;
    background-color: transparent;
    font-weight: normal !important;
}
.select-box-div .css-11unzgr {
    padding: 30px 0 10px;
    box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.1);
}
.select-box-div .css-ik6y5r {
    box-shadow: none;
}
.select-box-div > div > div {
    width: 100% !important;
    margin-top: 15px !important;
}
.select-box-div > div > div:nth-of-type(1):before {
    content: "";
    position: absolute;
    z-index: 9999;
    right: 33px;
    top: -11px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 12px solid white;
}
.select-box-div .css-11unzgr > div > input {
    height: auto !important;
    margin-right: 20px !important;
}

/******************************custom select***********************************************/

.assign_in_tags .css-1hwfws3, .assign_in_tags .css-g1d714-ValueContainer { 
    background: none;
    padding-left: 30px !important;
}
.custom_select_tag .css-yk16xz-control {
    border: none !important;
}
.custom_select_tag .css-yk16xz-control .css-1hwfws3 {
/*    / height: 60px; /*/
    padding: 0px 30px;
}
.css-1wa3eu0-placeholder {
    margin: 0 !important;
    /* position: relative!important; */
    transform: inherit!important;
    top: auto!important;
    color: #8d93a7!important;
    font-size: 1.125rem !important;
}
.css-tlfecz-indicatorContainer {
    padding-right: 30px;
	color: #c0eabe; 
    transform: none;
    top: 0;
    position: relative;
}
.common_dropdown .css-yk16xz-control, .common_dropdown .css-1pahdxg-control {
    background: url('../images/arrow.svg') no-repeat #fff;
    background-position: right 30px top 27px !important;
}
.common_dropdown .css-1wy0on6, .common_dropdown .css-1hb7zxy-IndicatorsContainer {
    display: none !important;
}
.common_dropdown .css-1fhf3k1-control{
    background: url('../images/arrow.svg') no-repeat #e4e4e4;
    background-position: right 30px top 27px !important;
    border: none;
}
/*.css-1wy0on6 div.css-tlfecz-indicatorContainer:last-child {
    padding: 0 !important;
    background: url(/static/media/arrow.svg) no-repeat 100%;
    background-size: 18px;
    margin-right: 30px;
}
.css-1wy0on6 div.css-tlfecz-indicatorContainer:last-child svg {
    width: auto !important;
    height: auto !important;
    visibility: hidden;
}
.css-1wy0on6 div.css-1gtu0rj-indicatorContainer:last-child {
    padding: 0 !important;
    background: url(/static/media/arrow.354ce7b0.svg) no-repeat 100%;
    background-size: 18px;
    margin-right: 30px;
}
.css-1wy0on6 div.css-1gtu0rj-indicatorContainer:last-child svg {
    width: auto !important;
    height: auto !important;
    visibility: hidden;
}
.css-1wy0on6 div.css-1gtu0rj-indicatorContainer:first-child {
    padding: 0 !important;
    background: url(/static/media/arrow.354ce7b0.svg) no-repeat 100%;
    background-size: 18px;
    margin-right: 30px;
}
.css-1gtu0rj-indicatorContainer svg.css19bqh2r{
    width: auto!important;
    height: auto!important;
    visibility: hidden;
}*/
.css-1pahdxg-control{
    border: none !important;
    box-shadow : none !important;
}
.css-1hwfws3{
    padding: 0 30px !important;
}
.add_tag_section .css-1hwfws3{
    height: auto !important;
}
/*search-box*/
.css-1hwfws3 {
    height: 60px;
    padding: 0 30px 0 50px !important;
}
.css-yk16xz-control {
    border: none;
}
.css-1uccc91-singleValue {
    position: relative !important;
    transform: inherit !important;
    top: auto !important;
    color: #8D93A7 !important;
}
.css-yk16xz-control {
    border: none !important;
}
.react-datepicker-wrapper{
    width: 100% !important;
    padding: 0;
}
.css-1hwfws3, .css-g1d714-ValueContainer {
    /*background: url(/static/media/serach.4e455070.svg) no-repeat 20px;*/
    background: url('../images/serach.svg') no-repeat 20px;

}
.css-1g6gooi {
    height: 54px;
    margin: 0 !important;
    padding: 0 !important;
}

span.css-1okebmr-indicatorSeparator {
    display: none;
}
/*search-box*/
.pagination_section{
    justify-content: flex-end;
}
.admin_left_section .user-profile-img {
    width: 200px;
}
.modal_from .css-yk16xz-control {
    background-color: #f6f6f6;
}
.modal_from .css-yk16xz-control {
    background-color: #f6f6f6;
}
.modal_from .css-1pahdxg-control{
    background-color: #f6f6f6;
}
span.dot_class {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.agenda_fixed_section .blue_btn, .agenda_fixed_section .gray_btn{
    width: 46.666% !important;
    float: left;
    margin-right: 10px
}
.agenda_fixed_section .pink_btn{
    width: 100% !important;
    margin: 20px 0 0 0 !important
}
.select-box-div button span.css-1gpjby2 svg {
    visibility: hidden;
}
.select-box-div button span.css-1gpjby2 {
    /*background: url(/static/media/arrow.354ce7b0.svg) no-repeat center right;*/
    background: url('../images/arrow.svg') no-repeat center right;
    margin: 0;
}
.assigned_tag{
    margin-bottom: 6px !important;
}
.news_third_part .carousel.carousel-slider .control-arrow {
    bottom: -35px;
    top: auto;
    background: url('../images/slider-arrow.svg') no-repeat !important;
    /*background: url(/static/media/smile.c8862d6f.svg) no-repeat;*/
    opacity: 1;
    padding: 0;
    width: 20px;
    height: 13px;
}
.comment-reaction {
    margin-top: 45px;
}

.carousel.carousel-slider {
    overflow: inherit !important;
}
.news_third_part .carousel .control-dots {
    bottom: -50px;
    display: none ;
}
.news_third_part .carousel .carousel-status {
    display: none;
}
.news_third_part .carousel.carousel-slider .control-arrow:hover{
    background-color: transparent;
}
.news_third_part .carousel.carousel-slider .control-arrow.control-next {
    transform: rotate(180deg);
}
.news_third_part .carousel .control-dots li.dot {
    background-color: #4aa546;
    box-shadow: none !important;
    margin: 0 5px;
}
.news_third_part .carousel .control-dots li.dot.selected {
    width: 18px;
    border-radius: 15px;
}
.ReactModal__Content.ReactModal__Content--after-open {
    position: relative !important;
    min-height: calc(100% - (1.75rem * 2));
    transition: none !important;
    margin: 1.75rem auto;
    top: auto !important;
    left: auto !important;
    bottom: auto !important;
    right: auto !important;
    display: flex;
    align-items: center;
    transform: translate(0,0) !important;
    background-color: transparent !important;
    border: none !important;
    padding: 0 !important;
    overflow: unset !important;
}
form.modal_from {
    padding: 40px !important;
    background-color: #fff;
    border-radius: 4px;
}
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}
.upload-btn-wrapper .btn {
    border: none;
    color:#4aa546;
    background-color: #f6f6f6;
    padding: 8px 20px;
    border-radius: .25rem;
    font-size: 16px;
    width: 100%;
    height: 54px;
    font-weight: bold;
}

.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
.admin-bottom-part .pink_btn {
    margin: 0 !important;
}
.custom-control-label {
    line-height: 1.8 !important;
}
.caution-img{
    width: 30px
}

/* New Css */

.winner_name > div {
    /* max-width: 98px; */
    margin-top: 7px;
    font-weight: 500;
    font-family: "CircularStd-Bold";
}
.matches_list.list {
    height: 383px;
}
.matches_list:hover {
    border: 1px solid #4aa546;
}
.bidder-name > h6 {
    margin: 0;
    color: #4aa546;
    font-weight: 500;
    font-family: "CircularStd-Bold";
}
.bidder-name > p {
    font-weight: 500;
    font-family: "CircularStd-Bold";
}
.matches_list .news_third_part_five>div {
    /* max-width: 98px;
    border-radius: 100%; */
    height: auto;
    overflow: auto;
}
.infinite-scroll-component__outerdiv {
    flex: 0 0 100%;
    max-width: 100%;
}
.tournament{
    height: 245px !important;

}
.tournament .news_third_part_four {
    height: 163px !important;
}
.circules_section ul > li{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-bottom: 1px;
    margin-left: auto;
  }
  .circules_section ul > li.green.active{
   
    background-color: #4aa546;
  }
  .circules_section ul > li.green{
    border: 1px solid #4aa546;
  
  }
  .circules_section ul > li.yellow{
    border: 1px solid #d5c825;
   
  }
  .circules_section ul > li.yellow.active{
    background-color: #d5c825;
  }
  .circules_section ul > li.red{
    border: 1px solid #d0021b
  }
  .circules_section ul > li.red.active{
    background-color: #d0021b;
  }
  .form-group .matches_dropdown {
    background-color: #fff;
    padding: 15px !important;
    margin-right: 0 !important;
   }
   .faq_position input:active, .faq_position input:focus{
       border:  1px solid #767676 !important;
    }
    .matches_list .circules_section ul>li {
        margin: 0 0 0 1px;
    }
    .matches_list .circules_section ul {
        display: inline-flex;
    }
    .multiple_matches_section {
     background-color: #fff;
     border-radius: 5px;
     padding: 30px 15px 0px 30px;
     position: relative;
     margin: 0 0 30px 0 !important;
    }
   
    .multiple_matches_section:last-child {
       margin-bottom: 0;
    }
    .multiple_matches_section .blue_tag_btn.matches_dropdown {
        background-color: #f3f3fa;
        margin-bottom: 30px;
        text-align: left;
    }
    .multiple_matches_section .blue_tag_btn.matches_dropdown:last-child {
        margin-bottom: 0;
    }
    .multiple_matches_section .matches_list {
        height: 340px;
        width: 100%;
        margin: 0 0 30px 0!important;
        border: none;
        background-color: #f3f3fa;
        padding: 15px !important;
    }
    .black_color {
        color: black;
    }
    .multiple_matches_header {
        justify-content: flex-end;
        display: flex;
        margin-bottom: 20px;
    }
    .multiple_matches_header .cross_btn {
        top: -39px;
        right: -21px;
    }
    /* mobile -responsive */
    .mobile_nav{
        /* box-shadow: 0 .15rem 1.75rem 0 rgba(58,59,69,.15)!important; */
        padding: 10px !important;
        margin: 0;
        background-color: #FFF;
    }
    .mobile_nav .logo{
        max-width: 100%;
        width: 59%;
    }
    .mobile_nav .common_btn {
        padding: 10px !important;
        line-height: 20px !important;
    }
    .error-message {
        padding: 3px 0 0!important;
        position: absolute;
    }
    .c-avatar-img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
    }
    .c-avatar-img-medium {
        width: 80px;
        height: 80px;
        border-radius: 50%;
    }
    .ReactTable .rt-thead.-header{
        background: #F3F3FA !important;
        padding: 10px;
        box-shadow: none !important;
        border: none !important;
    }
    .ReactTable .rt-tbody{
        padding: 10px !important;
        border: none !important;
    }
    .ReactTable .rt-thead.-filters {
        padding: 10px !important;
    }